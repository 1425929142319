/* 
 * shared.css
 * stylesheet for homepage
 */


body:lang(en) {
  /* font-family: 'Quicksand Variable', sans-serif; */
  font-family: 'Zen Maru Gothic', sans-serif;
}

body:lang(zh-Hant) {
  /* font-family: 'Noto Sans HK', sans-serif; */
  font-family: 'Zen Maru Gothic', sans-serif;
  letter-spacing: 2px;
}

.serif:lang(en) {
  font-family: 'Quicksand Variable', sans-serif;
  /* letter-spacing: 1px; */
}

.serif:lang(zh-Hant) {
  font-family: 'Kosugi Maru', sans-serif;
  letter-spacing: 1px;
}

/* #navbarOffcanvasLeft {
  background-image: url( '../src_images/skh_bw_nbw.svg' );
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
} */
